import * as React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import backgroundImage from "../../image/fondo2.png";

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
 
  height: '70vh',
  '&::before': {
    content: '""',
    background: `url(${backgroundImage}) #000000`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    filter: 'brightness(50%) blur(3px)',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

const StyledH1 = styled('h1')(({ theme }) => ({
  zIndex: 1,
  position: 'relative',
  color: 'whitesmoke',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  fontSize: "2rem",
  [theme.breakpoints.down('sm')]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: "1.75rem",
  },
  [theme.breakpoints.between('md', 'lg')]: {
    fontSize: "2rem",
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    fontSize: "2.25rem",
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: "4.5rem",
  },
}));

const StyledP = styled('p')(({ theme }) => ({
  zIndex: 1,
  position: 'relative',
  color: 'whitesmoke',
  width: '80%',
  fontSize: "20px",
  textAlign: 'center',
  justifyContent: 'center',
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    fontSize: "16px",
  },
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: "18px",
  },
  [theme.breakpoints.between('md', 'lg')]: {
    fontSize: "20px",
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    fontSize: "22px",
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: "24px",
  },
}));

function BannerCentral() {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <StyledH1>{t("technologyToShine")}</StyledH1>
      <StyledP>{t("weUseLatestDeepLearning")}</StyledP>
    </StyledContainer>
  );
}

export default BannerCentral;
