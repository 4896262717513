import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Grid, Typography, Box, Button, CardMedia} from '@mui/material';
import { styled } from '@mui/system';
import logo from '../../image/logoluzamarilla.png'
import fondo from '../../image/fonfo3.png'
import foto1 from '../../image/imageRealista.jpg'
import { Link } from 'react-router-dom';
 import circulo1 from '../../image/circulos1.png'  
  import circulo2 from '../../image/circulos2.png'
  import circulo3 from '../../image/circulos3.png'
import insignia from '../../image/insignia.png'


const Logo = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  height: 150,
  filter: 'brightness(180%)',
  marginLeft: '10px'
});

const TextCentered = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: 'whitesmoke',
  marginTop: '50px',
  fontSize: useMediaQuery(theme.breakpoints.down('sm')) ? '16px' : '35px',
}));

const BackgroundImage = styled('div')({
  backgroundImage: `url(${fondo})`,
  backgroundSize: 'cover',
  height: 300,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  filter: ' ',
  
});

const data = [
  {name: 'Equipo de Trabajo', Humano: 100, Avatar: 1},
  {name: 'Live Stream', Humano: 4, Avatar: '24/7'},
  // Agrega más datos aquí
];

const AvataresHiper = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      
      <BackgroundImage>
      <Link to="/">
    <Logo src={logo} alt="logo" />
  </Link>
        <TextCentered variant="h1" sx={{ textAlign: 'center' }}> </TextCentered>
</BackgroundImage>
<Grid container spacing={2} sx={{ p: 1, justifyContent:'center', marginTop:'50px' }}>
  <Grid item xs={12} sm={isMobile ? 12 : 4} style={{ display: 'flex', justifyContent: 'center' }}>
    <img src={foto1} alt="" style={{width:'75%', boxShadow: '0 0 25px rgba(144, 243, 105, 0.5)',}} />
  </Grid>
  
  <Grid item xs={12} sm={isMobile ? 12 : 8} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Typography sx={{ color:'white', maxWidth: '80%', textAlign: 'center' }}>
    Ahondando en las profundidades de la IA ,desarrollamos estructuras de ARQUITECTURA
PROMPT, entrenamos modelos específicos de IA y les damos VIDA para que emerjan en
la superficie, en forma de AVATARES GENERATIVOS, a un mundo real donde serán
capaces de resolver , interactuar, transformar, ayudar, ofrecer y servir cualquier tipo de
funcionalidad para lo que hayan sido cuidadosamente entrenados generando un impacto
escalable y a la vez, único.
    </Typography>
    <br />
    <br />
    <Box 
  sx={{ 
    display: 'flex', 
    flexDirection: 'column',
    justifyContent: 'center', 
    alignItems: 'center', 
    position: 'relative' 
  }}
>
  <Box
    sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
    }}
  >
    <img 
      src={insignia}
      alt="Descripción de la imagen" 
      style={{ 
        width: '30%', marginBottom: '20px',
      }} 
    />
  </Box>
  <Box
    sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
    }}
  >
    <Typography 
      sx={{ 
        color:'white', 
        maxWidth: '80%', 
        textAlign: 'center', 
      }}
    >
     Trabajando con IA
entrenada en contenido, en
imagen y voz, en edicion de
video y automatizacion de
procesos.

    </Typography>
  </Box>
  <Button
      variant="outlined"
      component={Link}
      to="/contactanos"
      sx={{ 
        mt: isMobile ? 5 : 4, 
        backgroundColor: '#009b94', 
        color: 'white', 
        '&:hover': {
          backgroundColor: '#009b94',
          color: 'white',
        },
        textDecoration: 'none'
      }}
    >
      Contáctanos
    </Button>
</Box>
   
   
  </Grid>
  
</Grid>

<Grid container direction="row" spacing={2} sx={{ p: 1, justifyContent:'center', marginTop:'50px' }}>

<Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
    <Typography variant="h2" component="h1" gutterBottom color='white'>
     Tendencia
    </Typography>
  </Grid>
  <Grid item xs={12} sm={4} style={{ display: 'flex', justifyContent: 'center' }}>
    <CardMedia
      component="img"
      image={circulo1}
      alt="descripción de la imagen 1"
      style={{ width: '250px', objectFit: 'contain' }}
    />
  </Grid>
  <Grid item xs={12} sm={4} style={{ display: 'flex', justifyContent: 'center' }}>
    <CardMedia
      component="img"
      image={circulo2}
      alt="descripción de la imagen 2"
      style={{ width: '250px', objectFit: 'contain' }}
    />
  </Grid>
  <Grid item xs={12} sm={4} style={{ display: 'flex', justifyContent: 'center' }}>
    <CardMedia
      component="img"
      image={circulo3}
      alt="descripción de la imagen 3"
      style={{ width: '250px', objectFit: 'contain' }}
    />
  </Grid>
  </Grid>
  
 

    </Box>
  );
}

export default AvataresHiper;