import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import en from './locales/en';
import es from './locales/es';

i18n
    .use(resourcesToBackend((language, namespace, callback) => {
        if (language === 'en') {
            callback(null, en);
        } else if (language === 'es') {
            callback(null, es);
        } else {
            callback('language not supported', {});
        }
    }))
    .use(initReactI18next)
    .init({
        lng: 'en',
        fallbackLng: 'en',
        debug: true,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;