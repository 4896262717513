import React from "react";
import {
  Button,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import video from "../../image/livestreaming.mp4";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Bentonico() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: "#FDFEFE",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "auto",
        mt: isMobile ? -2 : -4,
        p: isMobile ? 2 : 4,
      }}
    >
      <Typography
        variant={isMobile ? "h4" : "h3"}
        component="div"
        gutterBottom
        sx={{
          color: "#259a90",
          width: "80%",
          textAlign: "center",
          fontFamily: "Alata, sans-serif",
        }}
      >
        {t("Livestreaming")}
      </Typography>
      <Typography
        variant={isMobile ? "h4" : "h3"}
        component="div"
        gutterBottom
        sx={{
          color: "#259a90",
          width: "80%",
          textAlign: "center",
          fontFamily: "Alata, sans-serif",
        }}
      >
        {t("Livestreaming2")}
      </Typography>
      <Box
        sx={{
          backgroundColor: "#FDFEFE",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "90%",
          height: "auto",
          mt: 2,
        }}
      >
         <video width={isMobile ? "100%" : "80%"} autoPlay loop muted>
          <source src={video} type="video/mp4" />
        </video>
        <Button
          variant="outlined"
          component={Link}
          to="/avatarHiper"
          sx={{
            mt: 2,
            mb: 9,
            backgroundColor: "#259A90",
            color: "white",
            "&:hover": {
              backgroundColor: "#259A90",
              color: "#259A90",
            },
            textDecoration: "none",
            fontSize: isMobile ? "0.8rem" : "1rem",
            padding: isMobile ? "6px 12px" : "8px 16px",
          }}
        >
          {t("learnMore")}
        </Button>
      </Box>
    </Box>
  );
}

export default Bentonico;
