import { Grid, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import image1 from '../../image/a.png'; 
import image2 from '../../image/b.png';
import image3 from '../../image/N.png';
import image4 from '../../image/d.png';
import image5 from '../../image/e.png';
import image6 from '../../image/f.png';

const Image = styled('img')(({ theme }) => ({
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(2), 
  height: '50px',
  width: '50px',
  [theme.breakpoints.up('sm')]: {
    height: '75px',
    width: '75px',
  },
  [theme.breakpoints.up('md')]: {
    height: '100px',
    width: '100px',
  },
  [theme.breakpoints.up('lg')]: {
    height: '125px',
    width: '125px',
  },
  [theme.breakpoints.up('xl')]: {
    height: '150px',
    width: '150px',
  },
}));

const CircleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column', 
  alignItems: 'center', 
  justifyContent: 'center', 
  marginTop: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const CircleNumber = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontSize: {
    xs: '8px', 
    sm: '10px', 
    md: '12px', 
    lg: '14px', 
    xl: '16px', 
  },
}));



function Cards() {
  return (
    <Box 
  sx={{ 
    flexGrow: 1, 
    height: { xs: '100vh', sm: '110vh', md: '100vh'}, 
  
    backgroundColor: "#0b1638",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
   
  }}
> 
      <Grid container spacing={3} justifyContent="center"> 
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <CircleContainer>
            <Image src={image6} alt="image1" /> 
            <CircleNumber>Service 24/7</CircleNumber>
          </CircleContainer>
          <CircleContainer>
  <Image src={image3} alt="image2" /> 
  <Box display="flex" flexDirection="column" alignItems="center" >
    <CircleNumber>One AI model</CircleNumber>
    <CircleNumber>=</CircleNumber>
    <CircleNumber>One human or</CircleNumber>
    <CircleNumber>a million of them</CircleNumber>
  </Box>
</CircleContainer>
          <CircleContainer>
            <Image src={image2} alt="image3" /> 
            <Box display="flex" flexDirection="column" alignItems="center">
              <CircleNumber>Clone that</CircleNumber>
              <CircleNumber>works for you</CircleNumber>
            </Box>
          </CircleContainer>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <CircleContainer>
            <Image src={image5} alt="image4" /> 
            <CircleNumber>40 languages</CircleNumber>
          </CircleContainer>
          <CircleContainer>
            <Image src={image4} alt="image5" /> 
            <CircleNumber>Talk to human</CircleNumber>
          </CircleContainer>
          <CircleContainer>
            <Image src={image1} alt="image6" /> 
            <CircleNumber>Custom trained</CircleNumber>
          </CircleContainer>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Cards;
