import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Slider.css";
import imagen1 from "../../image/casosop.png";
import imagen2 from "../../image/casore.png";
import imagen3 from "../../image/casof.png";
import { Button, Box } from "@mui/material";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

function Slider() {
  const [index, setIndex] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isMedium = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLarge = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isExtraLarge = useMediaQuery(theme.breakpoints.up("xl"));
  const isUltraWide = useMediaQuery("(min-width:1920px)");

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const handleMouseMove = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / e.target.offsetWidth) * 100;
    const y = ((e.clientY - rect.top) / e.target.offsetHeight) * 100;
    e.target.style.setProperty("--x", `${x}%`);
    e.target.style.setProperty("--y", `${y}%`);
  };
  const { t } = useTranslation();

  return (
    <div
      className="container-fluid spotlight"
      style={{ backgroundColor: "#259A90" }}
      onMouseMove={handleMouseMove}
    >
      <h1 className="text-center mb-5 text-white">{t("Marca")}</h1>
      <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      className="mx-auto"
      style={{ maxWidth: "100%" }}
      controls={true}
      indicators={false}
    >
      <Carousel.Item>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            className="d-block img-fluid"
            src={imagen2}
            alt="First slide"
            style={{ maxWidth: isMobile ? '100%' : isTablet ? '70%' : '30%' }}
          />
        </div>
        <Carousel.Caption className="mobile-text">
          <div>
            {/* Content */}
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            className="d-block img-fluid"
            src={imagen1}
            alt="Second slide"
            style={{ maxWidth: isMobile ? '100%' : isTablet ? '70%' : '30%' }}
          />
        </div>
        <Carousel.Caption className="mobile-text">
          <div>
            {/* Content */}
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            className="d-block img-fluid"
            src={imagen3}
            alt="Third slide"
            style={{ maxWidth: isMobile ? '100%' : isTablet ? '70%' : '30%' }}
          />
        </div>
        <Carousel.Caption className="mobile-text">
          <div>
            {/* Content */}
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
      <Box display="flex" justifyContent="center">
        <Button
          variant="outlined"
          component={Link}
          to="/avatarHiper"
          sx={{
            mt: isMobile ? 5 : 8,
            backgroundColor: "#0b1638",
            color: "white",
            "&:hover": {
              backgroundColor: "#0b1638",
              color: "white",
            },
            textDecoration: "none",
            fontSize: isMobile
              ? "0.8rem"
              : isMedium
              ? "1rem"
              : isLarge
              ? "1.2rem"
              : isExtraLarge
              ? "1.4rem"
              : isUltraWide
              ? "1.8rem"
              : "1rem",
            padding: isMobile
              ? "6px 12px"
              : isMedium
              ? "8px 16px"
              : isLarge
              ? "10px 20px"
              : isExtraLarge
              ? "12px 24px"
              : isUltraWide
              ? "14px 28px"
              : "8px 16px",
          }}
        >
          {t("Button2")}
        </Button>
      </Box>
    </div>
  );
}

export default Slider;
