import * as React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import logo from "../../image/logoluzamarilla.png";
import fondo from "../../image/fonfo3.png";
import { Link } from "react-router-dom";

const Logo = styled("img")({
  position: "absolute",
  top: 0,
  left: 0,
  height: 150,
  filter: "brightness(180%)",
  marginLeft: "10px",
});

const TextCentered = styled(Typography)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "whitesmoke",
  marginTop: "50px",
  fontSize: useMediaQuery(theme.breakpoints.down("sm")) ? "16px" : "35px",
}));

const BackgroundImage = styled("div")({
  backgroundImage: `url(${fondo})`,
  backgroundSize: "cover",
  height: 300,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  filter: " ",
});

const Privacy = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        color: "#333",
        textAlign: "left",
        fontFamily: "Alata, sans-serif",
      }}
    >
      <BackgroundImage>
        <Link to="/">
          <Logo src={logo} alt="logo" />
        </Link>
        <TextCentered variant="h1" sx={{ textAlign: "center" }}>
          {" "}
        </TextCentered>
      </BackgroundImage>
      <Typography
        variant={"p"}
        component="div"
        maxWidth={"960px"}
        mx={"auto"}
        px={isMobile ? 2 : 4}
        py={isMobile ? 2 : 4}
      >
        1.- IDENTIFICACIÓN DEL RESPONSABLE DEL TRATAMIENTO Titular: HEALTH
        METAVERSE S.L ¿Dónde estamos?: Calle Rodriguez San Pedro 2, oficina 606
        NIF: B-88627930 Contacto: avatar@bentonico.ai
      </Typography>
      <Typography
        variant={"p"}
        component="div"
        maxWidth={"960px"}
        mx={"auto"}
        px={isMobile ? 2 : 4}
        py={isMobile ? 2 : 4}
      >
        2.- INFORMACIÓN Y CONSENTIMIENTO De conformidad con lo dispuesto en el
        Reglamento (UE) 2016/679, General de Protección de datos personales (en
        adelante, “RGPD“) así como la Ley Orgánica 3/2018, de 5 de diciembre,
        sobre Protección de datos personales y garantía de los derechos
        digitales (en adelante, “LOPDGDD”), mediante la aceptación de la
        presente Política de Privacidad el usuario queda informado y, cuando
        proceda, presta su consentimiento informado, expreso, libre e inequívoco
        para que los datos personales que proporcione a través del sitio web de
        https://www.bentonico.ai/ (en adelante, el “Sitio Web”) sean tratados
        por el Responsable de conformidad con las finalidades indicadas a
        continuación en la presente política de privacidad de una forma
        sencilla, fácilmente accesible y transparente.
      </Typography>
      <Typography
        variant={"p"}
        component="div"
        maxWidth={"960px"}
        mx={"auto"}
        px={isMobile ? 2 : 4}
        py={isMobile ? 2 : 4}
      >
        3.- OBLIGATORIEDAD DE FACILITAR LOS DATOS Los datos solicitados en los
        formularios dispuestos en el Sitio Web son, con carácter general,
        obligatorios (salvo que en el campo requerido se especifique lo
        contrario) para cumplir con las finalidades establecidas. Por lo tanto,
        si no se facilitan los mismos o no se facilitan correctamente, no podrán
        atenderse las solicitudes de los usuarios. En caso de que el usuario
        facilite datos de terceros, manifiesta contar con el consentimiento de
        estos y se compromete a trasladar al interesado, titular de dichos
        datos, la información contenida en la Política de Privacidad, eximiendo
        a HEALTH METAVERSE S.L de cualquier responsabilidad en este sentido.
      </Typography>
      <Typography
        variant={"p"}
        component="div"
        maxWidth={"960px"}
        mx={"auto"}
        px={isMobile ? 2 : 4}
        py={isMobile ? 2 : 4}
      >
        4.- ¿CON QUÉ FINALIDAD TRATAMOS TUS DATOS? Los datos personales que
        tratamos a través del Sitio Web serán utilizados para las siguientes
        finalidades: Responder a las solicitudes de información o a comentarios
        del visitante o usuario y gestionar sus consultas o comentarios
        realizados a través de los medios de contacto web. Gestionar las
        candidaturas de empleo recibidas a través de los medios de contacto del
        Responsable. Gestionar el registro de los usuarios y creación de cuenta
        en la plataforma a través del cual será posible realizar las siguientes
        acciones: Participar y formar parte de las salas y eventos establecidos
        en el Sitio Web respecto usuarios. Debes tener en cuenta que es posible
        que tus datos personales identificativos, incluida tu imagen (si la has
        decidido cargar en la página  voluntariamente) sean visibles para otros
        usuarios, junto con la indicación del   número de eventos creados o en
        los que participas. Introducir tus datos de pago como cuenta bancaria
        (IBAN) y/o número de tarjeta, cuenta de Paypal, Stripe, pasarela de
        pagos o cualquier otra plaforma de pago, envío o recepción de dinero
        para el realizar la compra de tickets o entrada a salas o eventos de
        pago de la web. Datos bancarios (IBAN), de tarjeta de crédito o débito o
        los relativos a cualquier plataforma de pago como Paypal,Stripe o
        similar. Remitir comunicaciones comerciales: Los datos personales
        tratados para esta finalidad son: Datos identificativos: nombre,
        apellidos. Datos de contacto: dirección de correo electrónico. Datos de
        navegación y medidores, tal como se indica en la Política de Cookies.
        Permitir tu navegación por el Sitio Web y, en su caso, recordar tus
        preferencias: Los datos personales tratados para esta finalidad son:
        Datos de navegación: dirección IP. Otros datos, de acuerdo con lo
        dispuesto en la Política de Cookies. Cumplir nuestras obligaciones
        legales: Los datos personales tratados para esta finalidad son: Datos
        identificativos: nombre, apellidos. Datos de contacto: dirección de
        correo electrónico. Otros datos: cualquier dato personal que pueda ser
        incluido por ti en el campo correspondiente a la descripción personal.
      </Typography>
      <Typography
        variant={"p"}
        component="div"
        maxWidth={"960px"}
        mx={"auto"}
        px={isMobile ? 2 : 4}
        py={isMobile ? 2 : 4}
      >
        6.- LEGITIMACIÓN DEL TRATAMIENTO Y PLAZO DE CONSERVACIÓN DE SUS DATOS De
        acuerdo con la legislación de aplicación, HEALTH METAVERSE S.L debe
        contar con una base legitimadora válida para poder tratar tus datos
        personales. Por esta razón, te proporcionamos a continuación información
        detallada respecto a cada tipo de finalidad: Responder a las solicitudes
        de información o a comentarios: El tratamiento de tus datos con esta
        finalidad se basa en tu consentimiento manifestado de forma libre,
        inequívoca, específica e informada, a través de la remisión de la
        solicitud de información o comentario correspondiente. Gestionar las
        candidaturas de empleo: El tratamiento de tus datos con esta finalidad
        se basa en tu consentimiento manifestado de forma libre, inequívoca,
        específica e informada, a través de la remisión de la candidatura
        correspondiente. Gestionar el registro de los usuarios: El tratamiento
        de tus datos con esta finalidad se basa en el cumplimiento de la
        relación contractual mantenida con HEALTH METAVERSE S.L a través de la
        aceptación de los Términos y Condiciones de uso. Sin el tratamiento de
        los datos personales, no será posible mantener la relación contractual
        referida anteriormente. Remitir comunicaciones comerciales: El
        tratamiento de sus datos con esta finalidad puede basarse en diversas
        bases legitimadoras, de acuerdo con la naturaleza del tratamiento de
        datos:   Este tratamiento puede basarse en tu consentimiento manifestado
        de forma libre, inequívoca, específica e informada, a través de la
        marcación de la casilla correspondiente. Alternativamente, este
        tratamiento puede basarse en el interés legítimo de HEALTH METAVERSE S.L
        cuando existe una relación contractual previa, de acuerdo con lo
        dispuesto en la Ley de Servicios de la Sociedad de la Información y del
        Comercio Electrónico (Ley 34/2002) posibilitando, en todo momento, que
        puedas oponerte a este tipo de comunicaciones mediante un procedimiento
        gratuito y sencillo. Permitir tu navegación por el Sitio Web y, en su
        caso, recordar tus preferencias: El tratamiento de tus datos con esta
        finalidad se basa en la relación contractual previa, así como en el
        interés legítimo de HEALTH METAVERSE S.L en posibilitar la navegación
        del usuario y, asimismo, prevenir ataques cibernéticos, cuyo impacto
        para la privacidad del usuario es mínimo en comparación con la
        satisfacción de intereses legítimos que persigue HEALTH METAVERSE S.L
        con este tratamiento. Permitir la conservación y tratamiento de los
        datos pago: datos bancarios (IBAN), de tarjeta de crédito o débito o los
        relativos a cualquier plataforma de pago como Paypal,Stripe o similar.
        Cumplir nuestras obligaciones legales: El tratamiento de datos
        personales con esta finalidad es precisamente el cumplimiento de
        obligaciones a las que HEALTH METAVERSE S.L se encuentra sujeto de
        acuerdo con la normativa vigente.
      </Typography>
      <Typography
        variant={"p"}
        component="div"
        maxWidth={"960px"}
        mx={"auto"}
        px={isMobile ? 2 : 4}
        py={isMobile ? 2 : 4}
      >
        7.- PLAZOS DE CONSERVACIÓN DE TUS DATOS PERSONALES A continuación, te
        facilitamos información sobre el plazo de conservación de tus datos
        personales, atendiendo a la finalidad correspondiente. Así, para:
        Responder a las solicitudes de información o a comentarios: El
        tratamiento de tus datos con esta finalidad se mantendrá hasta que
        prescriban las eventuales responsabilidades que derivan de dichas
        acciones. Gestionar las candidaturas de empleo: El tratamiento de tus
        datos con esta finalidad se mantendrá, con carácter general, durante un
        año. Sin embargo, es posible que, si el candidato se une finalmente al
        equipo de HEALTH METAVERSE S.L, esta candidatura puede adjuntarse a la
        documentación del trabajador y, en este sentido, podrá mantenerse hasta
        que prescriban las eventuales responsabilidades que pudieran derivarse
        de la relación contractual de carácter laboral, de acuerdo con la
        legislación laboral. Gestionar el registro de los usuarios: El
        tratamiento de tus datos con esta finalidad se mantendrá mientras el
        registro con BENTONICO permanezca activo y, con posterioridad, hasta que
        prescriban las posibles responsabilidades derivadas de dicho registro.
        Remitir comunicaciones comerciales: El tratamiento de tus datos con esta
        finalidad se mantendrá, bien hasta que revoques tu consentimiento o,
        alternativamente, hasta que ejercites tu derecho de oposición, si existe
        una relación contractual previa contigo.   Permitir tu navegación por el
        sitio web y, en su caso, recordar tus preferencias: El tratamiento de
        tus datos con esta finalidad se mantendrá mientras la cuenta del usuario
        registrado con el Sitio Web subsista, y aún después, con el objeto de
        prevenir ataques cibernéticos y mantener la continuidad del negocio.
        Permitir la conservación y tratamiento de los datos pago: datos
        bancarios (IBAN), de tarjeta de crédito o débito o los relativos a
        cualquier plataforma de pago como Paypal,Stripe o similar. Cumplir
        nuestras obligaciones legales: El tratamiento de tus datos personales
        con esta finalidad se mantendrá hasta que prescriban las eventuales
        responsabilidades a las que se encuentra sujeto HEALTH METAVERSE S.L.
      </Typography>
      <Typography
        variant={"p"}
        component="div"
        maxWidth={"960px"}
        mx={"auto"}
        px={isMobile ? 2 : 4}
        py={isMobile ? 2 : 4}
      >
        8.- COMUNICACIÓN DE LOS DATOS A TERCEROS Tus datos personales podrán ser
        comunicados a otras entidades, de acuerdo con las siguientes
        circunstancias: / A las administraciones públicas, para el cumplimiento
        de las obligaciones a las que HEALTH METAVERSE S.L se encuentra sujeto.
        Además, HEALTH METAVERSE S.L utiliza diferentes proveedores para prestar
        sus servicios, con los que ha suscrito los correspondientes contratos de
        encargo de tratamiento, garantizando las medidas técnicas, organizativas
        y de seguridad de los datos personales tratados de acuerdo con la
        normativa sobre protección de datos. Si decides validarte y acceder
        empleando para ello las plataformas externas (Facebook o Google), serán
        comunicados a HEALTH METAVERSE S.L tus datos identificativos, a los
        exclusivos efectos de posibilitar el registro.
      </Typography>
      <Typography
        variant={"p"}
        component="div"
        maxWidth={"960px"}
        mx={"auto"}
        px={isMobile ? 2 : 4}
        py={isMobile ? 2 : 4}
      >
        9.- TRANSFERENCIAS INTERNACIONALES DE DATOS HEALTH METAVERSE S.L podrá
        llevar a cabo transferencias internacionales de datos a terceros países
        ajenos al Espacio Económico Europeo, de acuerdo con los proveedores de
        servicios que utiliza para prestar sus servicios. Sin embargo, dichas
        transferencias se encuentran debidamente regularizadas y, en
        consecuencia, cuentan con un nivel de protección adecuado a los
        estándares establecidos por la legislación europea sobre protección de
        datos.
      </Typography>
      <Typography
        variant={"p"}
        component="div"
        maxWidth={"960px"}
        mx={"auto"}
        px={isMobile ? 2 : 4}
        py={isMobile ? 2 : 4}
      >
        10.- REDES SOCIALES HEALTH METAVERSE S.L está presente en diferentes
        redes sociales como Facebook, Instagram, Twitter, LinkedIn o YouTube, en
        las que lleva a cabo diferentes   iniciativas como proporcionar
        información sobre sus proyecto o proyectos de otras entidades con las
        que colabora o propiciar la participación de sus seguidos en concursos,
        sorteos o iniciativas similares. Por tanto, HEALTH METAVERSE S.L es el
        Responsable del tratamiento de los datos de los seguidores en redes
        sociales de BENTONICO, de acuerdo con lo dispuesto en la Política de
        privacidad y, en su caso, las bases legales que se establezcan. Si eres
        un seguidor de BENTONICO en redes sociales, también puedes consultar
        información adicional sobre cada red social en su política de privacidad
        correspondiente.
      </Typography>
      <Typography
        variant={"p"}
        component="div"
        maxWidth={"960px"}
        mx={"auto"}
        px={isMobile ? 2 : 4}
        py={isMobile ? 2 : 4}
      >
        11.- EJERCICIO DE DERECHOS El usuario puede enviar un escrito a HEALTH
        METAVERSE S.L , en cualquier momento a la dirección indicada en el
        encabezado de la presente Política, o bien por medio de un correo
        electrónico a avatar@bentonico.ai, adjuntando fotocopia de su documento
        de identidad, en cualquier momento y de manera gratuita, para: / Revocar
        los consentimientos otorgados, si bien ello no afectará a la licitud de
        los tratamientos efectuados con anterioridad. Acceder a sus datos
        personales. Rectificar datos inexactos o incompletos. Solicitar la
        supresión de sus datos cuando, entre otros motivos, los datos no sean
        necesarios para los fines que motivaron su recogida. Oponerse al
        tratamiento de sus datos por parte de . Obtener de HEALTH METAVERSE S.L
        la limitación del tratamiento de los datos cuando se cumpla alguna de
        las condiciones previstas en la normativa de protección de datos.
        Solicitar la portabilidad de los datos facilitados por el usuario en
        aquellos casos previstos en la normativa. Finalmente, el usuario podrá
        interponer una reclamación relativa a la protección de sus datos
        personales ante la Agencia Española de Protección de Datos en la
        dirección Calle de Jorge Juan, 6, 28001 Madrid, cuando considere que el
        Responsable haya vulnerado los derechos que le son reconocidos por la
        normativa aplicable en protección de datos. Última actualización: 30 de
        abril de 2020
      </Typography>
    </Box>
  );
};

export default Privacy;
