import "./App.css";
import Avatar from "./components/Avatar/Avatar";
import BannerCentral from "./components/BannerCentral/BannerCentral";
import BannerFinal from "./components/BannerFinal/BannerFinal";
import Bentonico from "./components/Bentonico/Bentonico";
import Cards from "./components/Cards/Cards";
import Empresas from "./components/Empresas/Empresas";
import Footer from "./components/Footer/Footer";
import HeaderHero from "./components/HeaderHero/HeaderHero";
import Metodos from "./components/Metodos/Metodos";
import Privacy from "./components/Privacidad/Privacy";
import Slider from "./components/Slider/Slider";
import Testimonios from "./components/Testimonios/Testimonios";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AvatarHiper from "./components/Avatares Hiperrealistas/AvataresHiper";
import Form from "./components/Form/Form";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  h1 {
    font-family: 'Alata', sans-serif;
  }
`;

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <>
              <GlobalStyle />
              <HeaderHero />
              <Avatar />
              <BannerCentral />
              <Cards />
              <Bentonico />
              <Slider />
              <Metodos />
              <Testimonios />
              <BannerFinal />
              <Empresas />
              <Footer />
            </>
          }
        />
        <Route path="/politica-de-privacidad" element={<Privacy />} />
        <Route path="/avatarHiper" element={<AvatarHiper />} />
        <Route path="/contactanos" element={<Form />} />
      </Routes>
    </div>
  );
}

export default App;
