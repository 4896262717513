import React from 'react';
import style from './Empresa.module.css';
import logob from '../../image/logo b.png';
import logomilo from '../../image/logo milo.png';
import logohospital from '../../image/logo hospital.png';
import logoilustracion from '../../image/ilustracion negro.png';
import logobalanzea from '../../image/logo balanzea.png';
import logouttopion from '../../image/Logo H2.png';
import { useTranslation } from "react-i18next";

function Empresas() {
  const { t } = useTranslation();
  return (
    <div className={style.container} style={{backgroundColor:'#0b1638', height:'300px'}}>
      <h1 className={style.h1} style={{color:'white'}}>{t("Proud")}</h1>
      <div className={style.logosContainer}>
        <img src={logob} alt="" className={style.logo} />
        <img src={logomilo} alt="" className={style.logo} />
        <img src={logohospital} alt="" className={style.logo} />
        <img src={logoilustracion} alt="" className={style.logo} />
        <img src={logobalanzea} alt="" className={style.logo} />
        <img src={logouttopion} alt="" className={style.logo} />
      </div>
    </div>
  );
}

export default Empresas;
