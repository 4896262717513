import * as React from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import logobentonico from "../../image/logobnuevo.png";
import backgroundImage from "../../image/imagen1.png";
import { AppBar, Toolbar, Button, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Container from "@mui/material/Container";
import fondo from '../../image/hero.png'

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: "100vh",
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    height: "40vh",
  },
  [theme.breakpoints.between('sm', 'md')]: {
    height: "45vh",
  },
  [theme.breakpoints.between('md', 'lg')]: {
    height: "70vh",
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    height: "90vh",
  },
  [theme.breakpoints.up('xl')]: {
    height: "90vh",
  },
  "&::before": {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundImage: `url(${fondo})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    zIndex: 0,
    opacity: 0.5,
  },
}));

const StyledButton = styled(Button)({
  color: "#259A90", 
  backgroundColor: "transparent", 
  '&:hover': {
    backgroundColor: "transparent",
  },
});

const StyledLogo = styled("img")(({ theme }) => ({
  width: "50vh",
  
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 1,
  marginTop: "2rem",
  [theme.breakpoints.down('sm')]: {
    width: "30vh",
    marginTop: "1rem",
  },
  [theme.breakpoints.between('sm', 'md')]: {
    width: "40vh",
    marginTop: "1.5rem",
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: "50vh",
    marginTop: "2rem",
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    width: "60vh",
    marginTop: "2.5rem",
  },
  [theme.breakpoints.up('xl')]: {
    width: "70vh",
    marginTop: "3rem",
  },
}));

const StyledHeaderText = styled(Typography)(({ theme }) => ({
  color:'white',
  zIndex: 2,
  color:'white',
  fontSize: "2rem",
  marginTop: "6rem",
  fontSize: "5rem",
  width: "10%",
  
  [theme.breakpoints.down('sm')]: {
    fontSize: "1.2rem",
    marginTop: "0.9rem",
    width: "70%",
  },
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: "2rem",
    marginTop: "4rem",
    width: "60%",
  },
  [theme.breakpoints.between('md', 'lg')]: {
    fontSize: "2rem",
    marginTop: "5rem",
    width: "50%",
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    fontSize: "3.5rem",
    marginTop: "8.5rem",
    width: "40%",
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: "4rem",
    marginTop: "7rem",
    width: "30%",
  },
}));

const StyledImage = styled("img")(({ theme }) => ({
  width: "50vh",
  position: "relative",
  zIndex: 1,
  marginTop: "5rem",
  width: "10%",
  [theme.breakpoints.down('sm')]: {
    width: "200px",
    marginTop: "3rem",
  },
  [theme.breakpoints.between('sm', 'md')]: {
    width: "40vh",
    marginTop: "4rem",
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: "50vh",
    marginTop: "5rem",
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    width: "60vh",
    marginTop: "6rem",
  },
  [theme.breakpoints.up('xl')]: {
    width: "80vh",
    marginTop: "2rem",
  },
}));

// ...

const HeaderHero = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div>
      <Toolbar>
        <Box display="flex" justifyContent="flex-end" width="150%">
          <StyledButton color="inherit" size="small" onClick={() => changeLanguage("en")}>
            English
          </StyledButton>
          <StyledButton color="inherit" size="small" onClick={() => changeLanguage("es")}>
            Español
          </StyledButton>
        </Box>
      </Toolbar>
    
      <StyledBox>
        <StyledLogo src={logobentonico} alt="logo" style={{marginTop:'-35px'}} />
        <Box display="flex" flexDirection="column" justifyContent="center" width="100%" alignItems="center">
          <StyledImage src={backgroundImage} alt="background" />
          <StyledHeaderText variant="h1" className="h1" style={{width:'auto',}}>
            {t("header")}
          </StyledHeaderText>
        </Box>
      </StyledBox>
    </div>
  );
};

export default HeaderHero;
