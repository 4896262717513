import image from '../image/inglesunete.png'

const en = {
    "header": "Welcome to the AI Models Era",
    "hyperrealisticAvatars": "Hyperrealistic Avatars",
    "learnMore": "Learn More",
    "technologyToShine": "TECHNOLOGY TO SHINE",
    "weUseLatestDeepLearning": "We use the latest in deep learning and AI imaging to create avatars that are not only visually stunning, but also capable of interacting and participating in the digital world in a meaningful way. Our avatars are the perfect fusion of technology and creativity, designed to make your brand shine.",
    'image': image,
    'Marca': 'Your brand could be the next to shine',
    'Revolucionando': 'Game-changing Digital Retai',
    'Text1':'For a well-known clothing brand, we are creating an AI model that not only embodies the style and values ​​of the brand but also interacts with customers with that same profile. We intend to improve the shopping experience and increase sales by 30% with this method. In addition, the brand wants to have trained cloneinfluencers.',
    'Gigante':'A Technology Giant Redefines Customer Service',
    'Text2': 'In partnership with a leading technology company, we developed a custome AI model for your support service. Equipped with advanced AI capabilities, this avatar not only responds to queries and anticipates needs, but also improves customer satisfaction and optimizes response times.',
    'Startup': 'A Fintech Startup Stands Out',
    'Text3': 'A Expert banking assistant 1:1 with clients. 24/7. Multiple clients connected at the same time but with private 1:1 conversation.',
    'Button2':'CONTACT',
    'Custom': 'Custom project design adapted to your brand and values.',
    'Train':'We train AI and shed light on the solution with technology and our own development.',
    'Proyect': 'Project delivery, ready to enjoy the magic.',
    "Livestreaming":'SMART LIVESTREAMING.',
    "Expcetional":'Exceptional. The avatar they created for us has been a turning point in our digital strategy."',
    "Possibility":'The possibility of exponentially increasing the number of impacts improves the user experience and a high increase in sales."',
    "technology":'HIGH IMPACT TECHNOLOGY READY TO CHANGE THE WORLD',
    "ExpertBanner":' Experts in deep AI training with their own expert models in corporate, B2B and B2C.',
    "Proud":'Proud to serve',
    "Privacy":'Privacy Policy',
    "Livestreaming2":'Live AI model that reads and answers questions',
  };
  export default en;
