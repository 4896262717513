import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import fondo from '../../image/8.png'
import { styled } from '@mui/system';
import logo from '../../image/logoluzamarilla.png'
import Modal from '@mui/material/Modal';
import axios from 'axios';

export default function Form() {
    const [option, setOption] = React.useState('');
    const [open, setOpen] = React.useState(false);
  
    const handleChange = (event) => {
      setOption(event.target.value);
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
    
      const form = event.target;
      const optionInput = document.createElement('input');
      optionInput.type = 'hidden';
      optionInput.name = 'option';
      optionInput.value = option;
      form.appendChild(optionInput);
    
      axios.post(form.action, new FormData(form))
        .then(response => {
          if (response.status === 200) {
            
            alert('Formulario enviado con éxito');
           
            form.reset();
          }
        })
        .catch(error => {
         
          console.error('Error enviando el formulario', error);
        });
    };
  
    const BackgroundImage = styled('div')({
        backgroundImage: `url(${fondo})`,
        backgroundSize: 'cover',
        height: 350,
        display: 'flex',
        alignItems: 'center', // Centra el logo verticalmente
        justifyContent: 'center', // Centra el logo horizontalmente
        position: 'relative',
        
      });
  
      const Logo = styled('img')({
        height: 150,
        filter: 'brightness(150%)',
      });

   

    return (
        <Container  maxWidth="sm" sx={{ minHeight: '100vh', backgroundColor: 'black' }}>
            
            <Box 
                component="form" 
                noValidate 
                autoComplete="off"
                action="https://formspree.io/f/xbjnjzbz"
                method="POST"
                target="_blank"
                onSubmit={handleSubmit}
               
                
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 0,
                    backgroundImage: `url(${fondo})`,
                    backgroundSize: 'cover',
                }}
            >
                
      <Link to="/">
    <Logo src={logo} alt="logo" />
  </Link>
  

                <Typography component="h1" variant="h5" style={{color:'white'}}>
                   
                </Typography>
                <Paper 
                    sx={{
                        mt: 2,
                        p: 3,
                        width: '100%',
                        flexDirection: 'column',
                    }}
                    elevation={3}
                >
                    <TextField id="name" label="Nombre" variant="outlined" sx={{ mb: 2 }} fullWidth name="name" required />
<TextField id="phone" label="Teléfono" variant="outlined" sx={{ mb: 2 }} fullWidth name="phone" required />
<TextField id="address" label="Dirección de contacto" variant="outlined" sx={{ mb: 2 }} fullWidth name="address" required />
<FormControl fullWidth sx={{ mb: 2 }} required>
    <InputLabel id="demo-simple-select-label">Option</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={option}
      label="Option"
      onChange={handleChange}
    >
      <MenuItem value="AVATAR">AVATAR</MenuItem>
      <MenuItem value="METAHUMAN">METAHUMAN</MenuItem>
      <MenuItem value="HIPERREALISTA">HIPERREALISTA</MenuItem>
    </Select>
  </FormControl>
<TextField
  id="outlined-multiline-static"
  label="Consulta"
  multiline
  rows={4}
  defaultValue=""
  variant="outlined"
  sx={{ mb: 2 }}
  fullWidth
  name="query"
  required
/>
<Button variant="contained" style={{ backgroundColor: '#009b94', color: 'white' }} type='submit' fullWidth>Enviar</Button>
                </Paper>
            </Box>
            <Modal
  open={open}
  onClose={() => setOpen(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box >
    <Typography id="modal-modal-title" variant="h6" component="h2">
      Formulario enviado
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      Tu formulario ha sido enviado con éxito.
    </Typography>
  </Box>
</Modal>
        </Container>
        
    );
}