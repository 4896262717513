import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import metodo from '../../image/metodo.png';
import fondo from '../../image/6.png'
import { useTranslation } from 'react-i18next';

const Container = styled(Box)(({ theme }) => ({
  background: `url(${fondo})`,
  backgroundSize: 'cover',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
 
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
  
  },
  [theme.breakpoints.between('sm', 'md')]: {
    height: 'auto',
 
  },
  [theme.breakpoints.up('md')]: {
    height: '135vh',
   
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    height: '120vh',
 
  },
  [theme.breakpoints.up('xl')]: {
    height: '140vh',
   
  },
}));

const Metodo = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '80%',
    marginTop: '50px',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    width: '70%',
    marginTop: '75px',
  },
  [theme.breakpoints.up('md')]: {
    width: '60%',
    marginTop: '100px',
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    width: '50%',
    marginTop: '125px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '40%',
    marginTop: '150px',
  },
}));

const Circles = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  marginTop: '20px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    width: '75%',
  },
  [theme.breakpoints.up('md')]: {
    width: '50%',
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    width: '25%',
  },
  [theme.breakpoints.up('xl')]: {
    width: '60%',
  },
}));

const Circle = styled(Box)(({ theme }) => ({
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '10px',
  backgroundColor: '#ccc',
  [theme.breakpoints.down('sm')]: {
    width: '40px',
    height: '40px',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    width: '45px',
    height: '45px',
  },
  [theme.breakpoints.up('md')]: {
    width: '50px',
    height: '50px',
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    width: '55px',
    height: '55px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '200px',
    height: '200px',
  },
}));
const CircleText = styled(Typography)(({ theme }) => ({
  marginTop: '10px',
  width: '50%',
  color: 'white',
  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: '12px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    fontSize: '16px',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '64px',
  },
}));

const CircleContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});
const CircleNumber = styled(Typography)(({ theme }) => ({
  color: 'white',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: '16px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    fontSize: '20px',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '52px',
  },
}));


export default function Metodos() {
  const { t } = useTranslation();

  return (
    <Container>
      <Metodo src={metodo} alt="" />
      <Typography style={{color:'white', fontSize:'30px',}}>PATENTED TECHNOLOGY</Typography>
    </Container>
  );
}
