import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import imagen0 from '../../image/testimonio4.jpg';
import imagen1 from '../../image/testimonio5.jpg';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";

function Testimonios() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  if (isMobile) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card sx={{ backgroundColor: 'black' }}>
            <CardMedia
              component="img"
              image={imagen0}
              alt="First slide"
              height="200"
            />
            <CardContent>
              <Typography variant="body2" color="white">{t("Expcetional")}</Typography>
              <Typography variant="caption" color="white" sx={{fontSize: { xs: '14px', sm: '16px', md: '18px', lg: '20px', xl: '22px' }}}>- David R., CEO de empresa FINTECH</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ backgroundColor: 'black' }}>
            <CardMedia
              component="img"
              image={imagen1}
              alt="Second slide"
              height="200"
            />
            <CardContent>
              <Typography variant="body2" color="white">{t("Possibility")}</Typography>
              <Typography variant="caption" color="white" sx={{fontSize: { xs: '14px', sm: '16px', md: '18px', lg: '20px', xl: '22px' }}}>-Alexandra M., Directora de Marketing </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100 img-fluid"
            src={imagen0}
            alt="First slide"
          />
          <Carousel.Caption style={{color:'black'}}>
            <Typography variant="h5" sx={{fontSize: { xs: '14px', sm: '16px', md: '18px', lg: '20px', xl: '22px' }}}>{t("Expcetional")}</Typography>
            <Typography variant="body1" sx={{fontSize: { xs: '14px', sm: '16px', md: '18px', lg: '20px', xl: '22px' }}}>- David R., CEO de empresa FINTECH</Typography>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 img-fluid"
            src={imagen1}
            alt="Second slide"
          />
          <Carousel.Caption style={{color:'black'}}>
            <Typography variant="h5" sx={{fontSize: { xs: '14px', sm: '16px', md: '18px', lg: '20px', xl: '22px' }}}>{t("Possibility")}</Typography>
            <Typography variant="body1" sx={{fontSize: { xs: '14px', sm: '16px', md: '18px', lg: '20px', xl: '22px' }}}>-Alexandra M., Directora de Marketing </Typography>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

export default Testimonios;
