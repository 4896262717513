import * as React from 'react';
import { Box, Typography,   Button } from '@mui/material';
import { styled } from '@mui/system';
import fondo from '../../image/hero.png';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";

const Container = styled(Box)({
  position: 'relative',
  backgroundImage: `url(${fondo})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  padding: '1rem',
  height: '300px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1,
  },
});

export default function BannerFinal() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  return (
    <Container>
      <Typography variant="h1" sx={{  fontFamily: 'Alata, sans-serif',fontSize: '1.9rem', fontWeight: 'bold', color: 'white', textAlign: 'center', position: 'relative', zIndex: 2, marginTop:'20px' }}>
      {t("technology")}
      </Typography>
      <Typography variant="body1" sx={{ fontSize: '1rem', color: 'white', textAlign: 'center', position: 'relative', zIndex: 2 }}>
      {t("ExpertBanner")}
      </Typography>
      <Box display="flex" justifyContent="center">
  <Button
    variant="outlined"
    component={Link}
    to="/contactanos"
    sx={{ 
      mt: isMobile ? 5 : 4, 
      backgroundColor: '#009b94', 
      color: 'white', 
      zIndex: 2,
      marginBottom:'20px',  
      '&:hover': {
        backgroundColor: '#009b94',
        color: 'white',
      },
      textDecoration: 'none',
    }}
  >
   {t("Button2")}
  </Button>
</Box>
    </Container>
  );
}


