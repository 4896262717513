import React from "react";
import {
  Button,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import video from "../../image/avatar.mp4";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Avatar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLarge = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isExtraLarge = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isUltraWide = useMediaQuery("(min-width:3440px)");
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#0b1638",
        height: isMobile ? "auto" : isMedium ? "100vh" : isLarge ? "80vh" : isExtraLarge ? "90vh" : isUltraWide ? "100vh" : "100vh",
      }}
    >
   
      <Box
        sx={{
          
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: isMobile ? "100%" : isMedium ? "700px" : isLarge ? "1000px" : isExtraLarge ? "1300px" : isUltraWide ? "1300px" : "700px",
          height: isMobile ? "auto" : isMedium ? "620px" : isLarge ? "820px" : isExtraLarge ? "620px" : isUltraWide ? "1020px" : "620px",
          mt: 7
          ,
        }}
      >
        <video width="100%" height="50%" autoPlay loop muted>
          <source src={video} type="video/mp4" />
        </video>
      </Box>
      <Button
        variant="outlined"
        component={Link}
        to="/avatarHiper"
        sx={{
          mt: isMobile ? 5 : -10,
          backgroundColor: "#259A90",
          color: "white",
          "&:hover": {
            backgroundColor: "#259A90",
            color: "white",
          },
          textDecoration: "none",
          fontSize: isMobile ? "0.8rem" : isMedium ? "1rem" : isLarge ? "1.2rem" : isExtraLarge ? "1.4rem" : isUltraWide ? "1.8rem" : "1rem",
          padding: isMobile ? "6px 12px" : isMedium ? "8px 16px" : isLarge ? "10px 20px" : isExtraLarge ? "12px 24px" : isUltraWide ? "14px 28px" : "8px 16px",
        }}
      >
        {t("learnMore")}
      </Button>
    </Box>
  );
}

export default Avatar;
