import image from '../image/españolunete.png'


const es = {
    "header": "Bienvenidos a la AI-Models Era",
    "hyperrealisticAvatars": "Avatares Hiperrealistas",
    "learnMore": "Saber Más",
    "technologyToShine": "TECNOLOGÍA PARA BRILLAR",
    "weUseLatestDeepLearning": "Utilizamos lo último en aprendizaje profundo y generación de imágenes por IA para crear avatares que no solo son visualmente impactantes, sino también capaces de interactuar y participar en el mundo digital de manera significativa. Nuestros avatares son la fusión perfecta de tecnología y creatividad, diseñados para hacer que tu marca brille.",
    'image': image,
    'Marca': 'TU MARCA PODRÍA SER LA PRÓXIMA EN BRILLAR',
    'Revolucionando': 'Revolucionando el retail digital ',
    'Gigante':'Un gigante tecnológico redefine la atención al cliente',
    'Text2': 'En colaboración con una empresa líder en tecnología, desarrollamos un AI model personalizado para su servicio de atención al cliente. Este avatar, equipado con capacidades avanzadas de IA, no solo responde a consultas y se anticipa a necesidades, sino que también mejora la satisfacción del cliente y optimiza los tiempos de respuesta.',
    'Startup': 'Una Startup de Fintech se Destaca ',
    'Text3': 'Experto asistente de banca en 1:1 con el clientes. 24/7 . Múltiples clientes conectados a la vez pero con conversación privada 1:1',
    'Button2':'CONTÁCTANOS',
    'Custom': 'Diseño de proyecto personalizado y adaptado a la marca y valores',
    'Train':'Entrenamos IA y ponemos luz a la solución con tecnologia y desarrollo propio.',
    'Proyect': ' Entrega del proyecto, listo para disfrutar de la magia.',
    "Livestreaming":'LIVESTREAMING INTELIGENTE. Ai model en directo que lee y responde preguntas ',
    "Expcetional":'"Excepcional.El avatar que crearon para nosotros ha sido un punto de inflexión en nuestra estrategia digital."',
    "Possibility":'"La posibilidad de exponenciar el número de impactos mejora la experiencia de usuario y alto incremento en ventas"',
    "technology":'TECNOLOGÍA DE ALTO IMPACTO PREPARADA PARA CAMBIAR EL MUNDO',
    "ExpertBanner":' Expertos en Entrenamiento profundo de IA con modelos propios expertos en corporativo , B2B y B2C.',
    "Proud":'EMPRESAS QUE CONFIAN EN NOSOTROS',
    "Privacy":'Politica de Privacidad',
  }; 
  export default es;
