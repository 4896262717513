import React from "react";
import style from "./Footer.module.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Footer() {
  const { t } = useTranslation();
  return (
    <div className={style.container}>
      <footer>
        <h6 className={style.h6} style={{ width: "100%" }}>
          <Link to="/politica-de-privacidad" style={{ color: "white" }}>
            {t("Privacy")}
          </Link>
        </h6>
      </footer>
    </div>
  );
}

export default Footer;
